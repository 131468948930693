const IMAGE_MAXIMUM_MEGABYTES = 30;

export function validateFileSizeLimit(formFiles) {
    if (formFiles instanceof FileList) {
        return Array.from(formFiles).some(file => file.size / 1024 / 1024 > IMAGE_MAXIMUM_MEGABYTES);
    } else {
        // In case of single file
        return formFiles.size / 1024 / 1024 > IMAGE_MAXIMUM_MEGABYTES;
    }
}

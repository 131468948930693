import React, { Component } from "react";
import * as Yup from "yup";
import axios from "axios";
import { Formik } from "formik";
import { withRouter } from "react-router-dom";
import { normalize } from "normalizr";
import * as Sentry from "@sentry/browser";

import i18n from "../../../../constants/i18n";
import { photo as photoSchema } from "../../../../schemas";
import createApiService from "../../../../network";
import ValidationError from "../../../../helpers/ValidationError";
import handleUploadProgress from "../../../../helpers/handleUploadProgress";

import PhotoUploadModalView from "./PhotoUploadModalView";
import { extractApiErrorMessage } from "../../../../helpers";
import { validateImageResolution } from '../../../../helpers/validateImageResolution';
import { validateFileSizeLimit } from '../../../../helpers/validateFileSizeLimit';

const supportedFormats = [
    "image/jpg",
    "image/jpeg",
    "image/png",
    "image/heif",
    "image/heic"
];

class PhotoUploadModalContainer extends Component {
    initialValues = {
        photo: ""
    };

    constructor(props) {
        super(props);

        this.api = createApiService(axios);
    }

    baseValidationSchema = Yup.object().shape({
        photo: Yup.mixed()
            .required(i18n.validation.required)
            .test("fileType", i18n.validation.fileType, value => {
                if (!value) return false;
                if (value instanceof FileList) {
                    return Array.from(value).every(file =>
                        supportedFormats.includes(file.type)
                    );
                }
                return supportedFormats.includes(value.type);
            })
            .test("fileLimit", i18n.validation.fileLimit, value => {
                if (!value) return false;
                if (value instanceof FileList) {
                    return Array.from(value).length <= 200;
                }
                return true;
            })
    });

    onSubmit = async (values, formikBag) => {
        const { ceremony } = this.props;

        if (validateFileSizeLimit(values.photo)) {
            this.props.showToast({
                body: i18n.photoUploadModal.fileSizeLimitWarning,
                title: "Error",
                themeClass: "is-danger"
            });

            formikBag.setSubmitting(false);
            return;
        }

        if (await validateImageResolution(values.photo)) {
            this.props.showToast({
                body: i18n.photoUploadModal.imageResolutionWarning,
                title: "Error",
                themeClass: "is-danger"
            });

            formikBag.setSubmitting(false);
            return;
        }

        formikBag.setStatus({ progress: 0 });

        try {
            if (values.photo instanceof FileList) {
                const body = {};
                for (let i = 0; i < values.photo.length; i++) {
                    body[`files[${i}]`] = values.photo[i];
                }
                await this.api.postMedia(ceremony.item.id, body, e =>
                    handleUploadProgress(e, formikBag.setStatus)
                );
            } else {
                await this.api.postMedia(
                    ceremony.item.id,
                    { "files[0]": values.photo },
                    e => handleUploadProgress(e, formikBag.setStatus)
                );
            }

            const {
                data: { data: photos }
            } = await this.api.getCeremonyPhotos(ceremony.item.id);

            const { entities } = normalize(photos, [photoSchema]);
            this.props.updateStoreEntities(entities);

            formikBag.setStatus({ uploadProgress: 100 });

            // Wait for progress bar transition
            setTimeout(() => {
                formikBag.setSubmitting(false);
                this.props.hideModal();
            }, 350);
        } catch (e) {
            if (e instanceof ValidationError) {
                formikBag.setErrors(e.errors);
                formikBag.setSubmitting(false);
            } else {
                Sentry.captureException(e);
                console.error(e);
                formikBag.setSubmitting(false);
                this.props.showToast({
                    body: extractApiErrorMessage(e),
                    title: "Error",
                    themeClass: "is-danger"
                });
            }
        }
    };

    render() {
        return (
            <Formik
                initialValues={this.initialValues}
                onSubmit={this.onSubmit}
                validationSchema={this.baseValidationSchema}
                render={props => (
                    <PhotoUploadModalView
                        deleteCrematory={() => this.deleteCrematory()}
                        hideModal={() => this.props.hideModal()}
                        isVisible={this.props.isVisible}
                        {...props}
                    />
                )}
            />
        );
    }
}

export default withRouter(PhotoUploadModalContainer);

const IMAGE_MAXIMUM_MEGAPIXELS = 80

export async function validateImageResolution(formFiles) {
    const checkDimensions = async file => {
        return new Promise(res => {
            const img = new Image();
            img.src = URL.createObjectURL(file);
            img.onload = () => {
                URL.revokeObjectURL(img.src);
                res(img.width * img.height / 1_000_000 > IMAGE_MAXIMUM_MEGAPIXELS);
            };
        });
    };

    if (formFiles instanceof FileList) {
        const results = await Promise.all(Array.from(formFiles).map(file => checkDimensions(file)));
        return results.some(result => result);
    } else {
        return await checkDimensions(formFiles);
    }
}
